<template>
    <div class="message_index">
    <van-tabs bind:click="onNavClick">
        <van-tab title="系统消息">
            <div class="list" v-if="newsListData.length">
                <div class="message_box" v-for="(item,index) in newsListData" :key="index" @click="toNewsPage">
                        <div class="cover">
                            <image :src="item.cover"></image>
                        </div>
                        <div class="title">{{item.title}}</div>
                        <div class="info">
                            <div class="category">
                                <van-tag plain type="success">{{item.category}}</van-tag>
                            </div>
                            <div class="date">
                                <van-icon name="underway-o" />{{item.created_at}}
                            </div>
                        </div>
                    </div>
                
            </div>
            <div v-if="!newsListData.length">
                    <van-empty description="暂无数据" />
                </div>
        </van-tab>
        <van-tab title="我的消息">
            <div class="my_message">
                <van-collapse accordion value="{{ activeName }}" @change="onChange" v-if="messageListData.length">
                    <van-collapse-item name="{{index+1}}" v-for="(item,index) in messageListData" :key="index">
                        <div slot="title">
                            <van-tag plain type="danger" class="tag">{{item.category}}</van-tag>{{item.title}}
                        </div>
                        {{item.content}}
                    </van-collapse-item>
                </van-collapse>
                <div  v-if="!messageListData.length">
                    <van-empty description="暂无数据" />
                </div>
            </div>
        </van-tab>
    </van-tabs>
</div>
    <global-menu></global-menu>
</template>
<script>
import { ref } from 'vue';
import GlobalMenu from "../../components/GlobalMenu.vue";
export default {
    components: {
        GlobalMenu,
    },
    setup() {
        const newsListData = ref([]);
        const messageListData = ref([]);
        return{
            newsListData,
            messageListData,
        }
    },
}
</script>
<style>
.message_box{overflow: hidden;margin-bottom: 15px;background-color: #ffffff;padding: 10px;}
.message_box .cover{width: 100%;height: 120px;margin-bottom: 10px;}
.message_box .cover image{width: 100%;height: 120px;border-radius: 10px;}
.message_box .title{font-size: 16px;color: #4a4a4a;margin-bottom: 10px;}
.message_box .info{display: flex;}
.message_box .category{font-size: 12px;color: #07C160;flex: 1;}
.message_box .date{font-size: 14px;color: #9a9a9a;text-align: right;}
.message_box .date van-icon{margin-right: 10px;}

.my_message .tag{margin-right: 10px;}
</style>